<template>
  <div class="ml_agreement">
    <body style="tab-interval: 36pt">
      <!--StartFragment-->
      <div class="Section0" style="layout-grid: 15.6pt">
        <p
          class="MsoTitle"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 15.65pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 1gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            line-height: 120%;
            text-align: center;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 20px;
                mso-font-kerning: 1pt;
              "
              ><font face="PingFang SC Semibold"
                >车赢云平台隐私权政策</font
              ></span
            ></b
          ><b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 20px;
                mso-font-kerning: 1pt;
              "
              ><o:p></o:p></span
          ></b>
        </p>
        <h1
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 1;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 16px;
                mso-font-kerning: 22pt;
              "
              ><font face="PingFang SC Semibold">简介</font></span
            ></b
          ><b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 16px;
                mso-font-kerning: 22pt;
              "
              ><o:p></o:p></span
          ></b>
        </h1>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 15.65pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 1gd;
            mso-para-margin-left: 0gd;
            text-indent: 24pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              color: rgb(0, 0, 0);
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 1pt;
            "
            ><font face="PingFang SC Regular">最新版本生效日期：</font
            ><font face="PingFang SC Regular">2022年03月10日</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              color: rgb(0, 0, 0);
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 1pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 15.65pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 1gd;
            mso-para-margin-left: 0gd;
            text-indent: 24pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >车赢云平台非常重视用户信息的保护，在使用车赢云平台及相关服务前，请您务必仔细阅读本隐私权政策，上海车赢信息技术有限公司</font
            ><font face="PingFang SC Regular"
              >((包括上海车赢信息技术有限公司的关联公司、控制公司,以下合称“公司”或“我们”)可能会为向您依照《车赢云平台用户使用协议》提供相关服务而收集、使用、储存您的相关信息，您一旦选择使用车赢云平台产品及相关服务，即意味着同意公司按照本隐私权政策收集、使用(含商业合作使用)、储存您的相关信息。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <h1
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 1;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 16px;
                mso-font-kerning: 22pt;
              "
              ><font face="PingFang SC Semibold">提示条款</font
              ><font face="PingFang SC Semibold">&nbsp;</font></span
            ></b
          ><b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 16px;
                mso-font-kerning: 22pt;
              "
              ><o:p></o:p></span
          ></b>
        </h1>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 15.65pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 1gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >隐私权政策适用于车赢云平台提供的客户端软件、网站、服务</font
            ><font face="PingFang SC Regular"
              >(统称为“车赢云平台产品及服务”)，包括但不限于适用于电脑、移动智能终端的产品及服务。本隐私权政策旨在帮助您了解我们会收集哪些数据、为什么收集这些数据、会利用这些数据做什么以及我们如何保护这些数据。了解这些内容，对于您行使个人权利及保护您的个人信息至关重要，请您在使用车赢云平台产品及服务前务必抽出时间认真阅读本隐私权政策。当您开始下载、访问或使用车赢云平台产品及服务，即表示您已经同意本隐私权政策并信赖我们对您的信息的处理方式。请注意，您可以通过产品中的设置功能或控制项对您的信息进行动态管理，也可在手机上关闭系统的消息通知。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              mso-bidi-font-weight: bold;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >我们同时提醒您，当您通过车赢云平台产品及服务使用第三方产品或服务时，您的信息应当适用第三方的隐私条款。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <h1
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 1;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 16px;
                mso-font-kerning: 22pt;
              "
              ><font face="PingFang SC Semibold">1、定义</font></span
            ></b
          ><b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 16px;
                mso-font-kerning: 22pt;
              "
              ><o:p></o:p></span
          ></b>
        </h1>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">1.1本产品</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              mso-bidi-font-weight: bold;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">：</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >指由公司合法拥有并运营的、标注名称为车赢云平台客户端应用程序、公司经所属企业授权为其开发和维护的微信小程序，公司向用户提供的车赢云平台相关的技术服务。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">1.2关联公司</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              mso-bidi-font-weight: bold;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">：</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >是指一方现在或将来控制、受控制或与其处于共同控制下的任何公司、合法机构以及上述公司、合法机构的合法继承人。控制是指</font
            ><font face="PingFang SC Regular"
              >(1)直接或间接持有过半数的投票权；或(2)直接或间接享有过半数的可分配利润；或(3)直接或间接控制董事会半数以上的成员的组成；或(4)直接或间接持有半数的注册资本。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">1.3个人信息</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              mso-bidi-font-weight: bold;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">：</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">1.4个人敏感信息</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              mso-bidi-font-weight: bold;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">：</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            >包括</span><span class="mark">身份证件号码</span>、<span class="mark">个人手机号码</span>、<span class="mark">邮箱</span>、<span class="mark">银行账号</span>、<span class="mark">行踪轨迹（定位信息）</span>、<span class="mark">交易信息</span><span>等</span>。
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">1.5个人信息删除</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              mso-bidi-font-weight: bold;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">：</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">1.6个人信息匿名化</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              mso-bidi-font-weight: bold;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">：</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">1.7其他非个人信息</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              mso-bidi-font-weight: bold;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">：</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >是指在您使用本产品时，本产品可能自动接收并记录您的网站或应用程序上的服务器数值。包括但不限于您输入的关键词、您要求取用的网页记录及各种记录、本产品的产品或服务的操作状态、网络环境、异常日志等一些明确且客观反映在本产品的产品或服务服务器端的基本记录信息。前述基本记录信息以及其他无法通过其识别您的个人身份的信息不属于您的个人信息。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 15.65pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 1gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">1.8所属企业</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              mso-bidi-font-weight: bold;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">：</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >指您受其雇佣或为其服务的公司或其他组织。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <h1
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 1;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 16px;
                mso-font-kerning: 22pt;
              "
              ><font face="PingFang SC Semibold"
                >2、我们如何收集和使用您的信息</font
              ></span
            ></b
          ><b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 16px;
                mso-font-kerning: 22pt;
              "
              ><o:p></o:p></span
          ></b>
        </h1>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 15.65pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 1gd;
            mso-para-margin-left: 0gd;
            text-indent: 24pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >我们会出于本隐私权政策所述的以下目的，收集、存储和使用下列与您有关的个人信息。如果您不提供相关信息，可能无法注册成为我们的用户或无法享受我们提供的某些产品或服务，或者无法达到相关服务拟达到的效果。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <h2
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 2;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><font face="PingFang SC Semibold"
                >2.1帮助您成为我们的用户</font
              ></span
            ></b
          ><b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><o:p></o:p></span
          ></b>
        </h2>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >为成为我们的用户以便我们为您提供用户服务，您需要提供手机号码、电子邮箱地址等信息创建用户名和密码，成为我们的注册用户。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >当您所属企业用户注销车赢云平台账号时，我们将会尽快匿名化处理或删除您在该组织的相关个人信息。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <h2
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><font face="PingFang SC Semibold"
                >2.2向您提供产品或服务</font
              ></span
            ></b
          ><b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><o:p></o:p></span
          ></b>
        </h2>
        <h3
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><font face="PingFang SC Regular"
                >2.2.1您向我们提供的信息</font
              ></span
            ></b
          ><b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><o:p></o:p></span
          ></b>
        </h3>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 24pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">需进行</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              mso-bidi-font-weight: bold;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">额外</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >说明的是，为确保流畅的产品体验，您可能会收到来自车赢云平台及其合作伙伴外部的第三方</font
            ><font face="PingFang SC Regular">(下文简称“</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              mso-bidi-font-weight: bold;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">第三方</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >”)提供的内容或网络链接。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              mso-bidi-font-weight: bold;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >我们对此类第三方无控制权。您可选择是否访问第三方提供的链接、内容、产品和服务。我们无法控制第三方的隐私和数据保护政策，此类第三方不受到本隐私权政策的约束。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >在向第三方提交个人信息之前，请自行查阅这些</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              mso-bidi-font-weight: bold;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >第三方的隐私保护政策并请您自行确定可以接受该等隐私保护政策</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">。</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 24pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >您在代表所属企业上传其成员或外部联系人的个人信息或其他数据前，应当确保已经事先取得了相关个人或权利人的明确同意，并且确保上述信息仅被用于收集时已经充分告知相关个人或权利人的目的和范围，并以收集时被告知的方式使用。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >第三方提供的连接、内容、产品和服务如下：</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <div style="width: calc(100vw - 30px); overflow: scroll">
          <table
            border="1"
            cellspacing="0"
            style="
              width: 100%;
              border-collapse: collapse;
              mso-table-layout-alt: fixed;
              border: none;
              mso-border-left-alt: 0.5pt solid windowtext;
              mso-border-top-alt: 0.5pt solid windowtext;
              mso-border-right-alt: 0.5pt solid windowtext;
              mso-border-bottom-alt: 0.5pt solid windowtext;
              mso-border-insideh: 0.5pt solid windowtext;
              mso-border-insidev: 0.5pt solid windowtext;
              mso-padding-alt: 0pt 5.4pt 0pt 5.4pt;
              table-layout: fixed;
            "
          >
            <tr>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: 1pt solid windowtext;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular">SDK</font></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: 1pt solid windowtext;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular">功能</font></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: 1pt solid windowtext;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular"
                      >收集个人信息类型</font
                    ></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: 1pt solid windowtext;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular">隐私政策链接</font></span
                  >
                </p>
              </td>
            </tr>
            <tr>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular">极光推送</font
                    ><font face="PingFang SC Regular">SDK</font></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular">提供推送</font
                    ><font face="PingFang SC Regular"
                      >/下发通知栏信息服务</font
                    ></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular"
                      ><span class="mark">设备标识符（包括IMEI、IDFA、Android ID、GAID、MAC、OAID、IMSI、MEID、UAID）、设备信息</span>、<span class="mark">地理位置信息</span>、<span class="mark">网络信息</span></font
                    ></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular"
                      >https://www.jiguang.cn/license/privacy</font
                    ></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular">华为推送</font
                    ><font face="PingFang SC Regular">SDK</font></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular">针对华为手机提供推送</font
                    ><font face="PingFang SC Regular"
                      >/下发通知栏信息服务</font
                    ></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular"
                      ><span class="mark">设备信息</span>、<span class="mark">地理位置信息</span>、<span class="mark">网络信息</span></font
                    ></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular"
                      >https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/sdk-data-security-0000001050042177</font
                    ></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular">小米推送</font
                    ><font face="PingFang SC Regular">SDK</font></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular">针对小米手机提供推送</font
                    ><font face="PingFang SC Regular"
                      >/下发通知栏信息服务</font
                    ></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular"
                      ><span class="mark">设备信息</span>、<span class="mark">地理位置信息</span>、<span class="mark">网络信息</span></font
                    ></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular"
                      >https://dev.mi.com/console/doc/detail?pId=1822</font
                    ></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular">OPPO推送SDK</font></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular">针对</font
                    ><font face="PingFang SC Regular"
                      >OPPO手机提供推送/下发通知栏信息服务</font
                    ></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular"
                      ><span class="mark">设备信息</span>、<span class="mark">地理位置信息</span>、<span class="mark">网络信息</span></font
                    ></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular"
                      >https://open.oppomobile.com/new/developmentDoc/info?id=10288</font
                    ></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular">VIVO推送SDK</font></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular">针对</font
                    ><font face="PingFang SC Regular"
                      >VIVO手机提供推送/下发通知栏信息服务</font
                    ></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular"
                      ><span class="mark">设备信息</span>、<span class="mark">地理位置信息</span>、<span class="mark">网络信息</span>、<span class="mark">应用列表</span></font
                    ></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular"
                      >https://www.vivo.com.cn/about-vivo/privacy-policy</font
                    ></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular">高德地图</font
                    ><font face="PingFang SC Regular">SDK</font></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular"
                      >展现地图、实现点位</font
                    ></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular"
                      ><span class="mark">设备信息</span>、<span class="mark">地理位置信息</span></font
                    ></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular"
                      >https://lbs.amap.com/pages/privacy/</font
                    ></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular">友盟</font
                    ><font face="PingFang SC Regular">+SDK</font></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular">统计分析</font></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular"><span class="mark">设备信息</span>、<span class="mark">应用列表</span>、</font
                    ><font face="PingFang SC Regular"><span class="mark">MAC地址</span></font></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular"
                      >https://www.umeng.com/page/policy</font
                    ></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular">io.dcloud，com.carwins.uni</font></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular">应用基座</font></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular"><span class="mark">设备信息</span>、<span class="mark">获取运行中进程信息</span>，<span class="mark">获取SD卡数据</span></font></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular"
                      >https://ask.dcloud.net.cn/article/39484</font
                    ></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
            </tr>

            <tr>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular">com.geetest</font></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular">一键登录验证</font></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular"><span class="mark">设备信息</span>、<span class="mark">设备网络信息</span></font></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular"
                      >https://www.geetest.com/Private</font
                    ></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular">com.netease.nis</font></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular">io.dcloud应用基座</font></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular"><span class="mark">获取oaid</span> <span class="mark">设备信息</span></font></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular"
                      >http://www.msa-alliance.cn/col.jsp?id=122</font
                    ></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular">com.aliyun,阿里云SDK</font></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular">io.dcloud应用基座</font></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular"><span class="mark">获取oaid</span> <span class="mark">设备信息</span>，<span class="mark">获取SD卡数据</span></font></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular"
                      >https://ask.dcloud.net.cn/article/39484</font
                    ></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
            </tr>

            
            <tr>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular">微信</font
                    ><font face="PingFang SC Regular">SDK</font></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular">信息分享</font
                    ><font face="PingFang SC Regular">/用户付款</font></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular"
                      ><span class="mark">设备信息</span>、<span class="mark">网络信息</span>、<span class="mark">应用列表</span></font
                    ></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular"
                      >https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8</font
                    ></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular">支付宝</font
                    ><font face="PingFang SC Regular">SDK</font></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular">用户付款</font></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular"
                      ><span class="mark">设备信息</span>、<span class="mark">网络信息</span>、<span class="mark">应用数据</span></font
                    ></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular"
                      >https://opendocs.alipay.com/common/02kiq3</font
                    ></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular">腾讯</font
                    ><font face="PingFang SC Regular">X5浏览器SDK</font></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular">浏览</font
                    ><font face="PingFang SC Regular">WEB页面</font></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular"><span class="mark">设备信息</span></font></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
              <td
                width="25%"
                valign="top"
                style="
                  width: 25%;
                  padding: 0pt 5.4pt 0pt 5.4pt;
                  border-left: 1pt solid windowtext;
                  mso-border-left-alt: 0.5pt solid windowtext;
                  border-right: 1pt solid windowtext;
                  mso-border-right-alt: 0.5pt solid windowtext;
                  border-top: none;
                  mso-border-top-alt: 0.5pt solid windowtext;
                  border-bottom: 1pt solid windowtext;
                  mso-border-bottom-alt: 0.5pt solid windowtext;
                "
              >
                <p
                  class="MsoNormal"
                  style="
                    margin-top: 7.85pt;
                    margin-right: 0pt;
                    margin-bottom: 15.65pt;
                    margin-left: 0pt;
                    mso-para-margin-top: 0.5gd;
                    mso-para-margin-right: 0gd;
                    mso-para-margin-bottom: 1gd;
                    mso-para-margin-left: 0gd;
                    text-autospace: ideograph-numeric;
                    mso-pagination: widow-orphan;
                    text-align: left;
                    line-height: 120%;
                  "
                >
                  <span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><font face="PingFang SC Regular"
                      >https://x5.tencent.com/docs/privacy.html</font
                    ></span
                  ><span
                    style="
                      font-family: 'PingFang SC Regular';
                      line-height: 120%;
                      mso-ansi-font-weight: normal;
                      font-size: 14px;
                      mso-font-kerning: 0pt;
                    "
                    ><o:p></o:p
                  ></span>
                </p>
              </td>
            </tr>
          </table>
        </div>
        <h3
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 3;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><font face="PingFang SC Semibold"
                >2.2.2我们在您使用本产品中收集的信息</font
              ></span
            ></b
          ><b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><o:p></o:p></span
          ></b>
        </h3>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 24pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            >日志信息，当您使用本产品时，我们会自动收集您对我们服务的使用情况，作为有关网络日志保存。例如您的<span class="mark">搜索查询内容</span>、<span class="mark">IP地址</span>、<span class="mark">浏览器的类型</span>、<span class="mark">使用的语言</span>、<span class="mark">访问服务的日期和时间</span>、<span class="mark">cookies</span>、 <span class="mark">web beacon</span>等。</span>
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 24pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >请注意，单独的设备信息、日志信息等是无法识别特定自然人身份的信息，为了在您使用设备上为您提供一致的服务，我们会将于您各种设备</font
            ><font face="PingFang SC Regular"
              >(计算机、移动设备)上收集到的信息进行关联。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 24pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">设备或应用信息</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              mso-bidi-font-weight: bold;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">：</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">当你使用</font
            ><font face="PingFang SC Regular"
              >“车赢云平台移动应用(App)时，为了保障您正常使用“车赢云平台”移动应用服务，更准确定位并解决您在使用“车赢云平台”移动应用服务时遇到的问题，改进及优化“车赢云平台”移动应用的服务体验，保障您的帐号安全，我们会收集<span class="mark">设备属性信息(例如您的硬件型号、操作系统版本、设备配置、唯一设备标识符、国际移动设备身份码IMEI、网络设备硬件地址MAC、广告标识符IDFA)，设备位置信息(如通过GPS、蓝牙或Wi-fi信号获得的位置信息)、设备连接信息(浏览器的类型、电信运营商、使用的语言)以及设备状态信息(例如设备传感器数据，设备应用安装列表)</span>。对于从您的各种设备上收集到的信息，我们可能会将它们进行关联，以便我们能在这些设备上为您提供一致的服务。我们可能会将您的设备信息或电话号码与您的车赢云平台帐户相关联。同时，请您理解，当您将处于设备屏幕中“车赢云平台”移动应用切换到设备后台运行时，由于部分设备机型及系统原因，您此前使用“车赢云平台”移动应用相关功能而产生的信息收集行为，可能无法立即停止，从而导致短暂的后台信息收集行为。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 24pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">位置信息</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              mso-bidi-font-weight: bold;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">：</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >当您开启设备定位功能并使用本产品基于位置提供的相关服务时，在获得您的同意后，我们会使用各种技术进行定位，以使得您不需要手动输入自身地理坐标就可获得相关服务。这些技术包括</font
            ><font face="PingFang SC Regular"
              >IP地址、GPS以及能够提供相关信息的其他传感器(比如可能需要提供附近设备、wi-Fi接入点和基站的信息)。您可以通过关闭定位功能，停止告知车赢云平台软件关于您的地理位置(大多数移动设备允许您关闭定位服务，具体建议您联系您的移动设备的服务商或生产商)。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 15.65pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 1gd;
            mso-para-margin-left: 0gd;
            text-indent: 24pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold"
                >允许程序访问摄像头进行拍照说明</font
              ></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              mso-bidi-font-weight: bold;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">：</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >更换头像及发送图片，调用摄像头拍摄；使用场景：</font
            ><font face="PingFang SC Regular"
              >1.设置我的头像更换头像。2.在线沟通-发送图片-拍照。3使用视频沟通功能获取摄像头权限。4.车辆信息编辑-车辆图片拍摄/车辆视频录制。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <h3
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 3;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><font face="PingFang SC Semibold"
                >2.2.3我们通过间接获得方式收集到的您的个人信息</font
              ></span
            ></b
          ><b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><o:p></o:p></span
          ></b>
        </h3>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 24pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >当您使用本产品接入的第三方服务时，可能需要提交您的个人信息</font
            ><font face="PingFang SC Regular"
              >(部分情况下包括您的<span class="mark">身份信息、联系方式、账号信息、昵称、评论内容、银行账号等以及个人明确授权可以共享的信息</span>)。您授权我们将该信息提供给为您提供服务的第三方，并授权我们间接向第三方获取相关信息。若您拒绝我们收集此类信息，我们将无法为您提供相关功能和服务，但不会影响您对产品或服务基础功能的使用。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 24pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >当您使用手机号码或其他我们允许的方式登录我们的产品或服务时，我们需要核实您的身份或绑定您的账户信息，经过您的授权将相关账号关联到我们的产品或服务中。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 24pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >如您拒绝提供上述信息或拒绝授权，可能无法使用我们关联公司或第三方的相应产品或服务，或者无法展示相关信息，但不影响使用本产品或服务的基础功能。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <h2
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 2;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><font face="PingFang SC Semibold">2.3其他用途</font></span
            ></b
          ><b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><o:p></o:p></span
          ></b>
        </h2>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">(a)</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >参与我们的调查及活动：我们将不定期地举办有关我们产品和服务的调查，或者围绕运营需要举办促销及抽奖活动，如果您在参与此类调查或活动时需要提供超过本隐私权政策许可的个人敏感信息，我们会事先征求您的同意。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">(b)</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >我们将信息用于本隐私权政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <h2
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 2;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><font face="PingFang SC Semibold">2.4您理解并同意</font></span
            ></b
          ><b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><o:p></o:p></span
          ></b>
        </h2>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 15.65pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 1gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >我们向您提供的功能和服务场景是不断迭代升级的，如我们未在上述场景中明示您需要收集的个人信息，我们将会通过页面提示、交互设计等方式另行向您明示信息收集的内容、范围和目的并征得您同意。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <h2
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 2;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><font face="PingFang SC Semibold"
                >2.5征得授权同意的例外根据相关法律法规规定</font
              ></span
            ></b
          ><b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><o:p></o:p></span
          ></b>
        </h2>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >以下情形中收集您的个人信息无需征得您的授权同意：</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >与国家安全、国防安全有关的；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >与公共安全、公共卫生、重大公共利益有关的；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >与犯罪侦查、起诉、审判和判决执行等有关的；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >所收集的个人信息是您自行向社会公众公开的；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >根据您的要求签订合同所必需的；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >为合法的新闻报道所必需的；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >法律法规规定的其他情形。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >如我们停止运营本产品的功能或服务，我们将及时停止继续收集您个人信息的活动，将停止运营信息以通知或公告的形式通知您，对所持有的个人信息进行删除或匿名化处理。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <h2
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 2;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><font face="PingFang SC Semibold"
                >2.6个人信息可能的使用方式</font
              ></span
            ></b
          ><b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><o:p></o:p></span
          ></b>
        </h2>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 24pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >我们会根据本隐私政策的约定并为实现我们的产品与</font
            ><font face="PingFang SC Regular"
              >/或服务功能对所收集的个人信息进行使用。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 24pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >在我们提供服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 24pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >为了确保服务的安全，帮助我们更好地了解我们应用程序的运行情况，我们可能记录相关信息数据，例如，您使用应用程序的频率、崩溃数据、总体使用情况、性能数据等。我们不会将我们存储在分析软件中的信息与您在应用程序中提供的任何个人身份信息相结合。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 24pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别主体。请您了解并同意，在此情况下我们有权使用已经去标识化的信息；并在未经您授权不得向第三方透露您个人信息的前提下，我们有权对用户数据库进行分析并予以我们认为合适的商业化的利用。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 24pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >请您注意，您在使用我们的产品与/或服务时所提供的所有个人信息，除非您删除或通过系统设置拒绝我们收集，否则将在您使用我们的产品与/或服务期间持续授权我们使用。在您注销账号后，我们将停止为您提供产品或服务，根据您的要求删除您的个人信息，或做匿名化处理，但法律法规另有规定的除外。</font
            ></span
          >
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 24pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              mso-bidi-font-weight: bold;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >我们会对我们的产品与/或服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示我们的产品与/或服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>

        <h1
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 1;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 16px;
                mso-font-kerning: 22pt;
              "
              ><font face="PingFang SC Semibold"
                >3、我们如何使用&nbsp;cookie和同类技术</font
              ></span
            ></b
          ><b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 16px;
                mso-font-kerning: 22pt;
              "
              ><o:p></o:p></span
          ></b>
        </h1>
        <h2
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 2;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><font face="PingFang SC Semibold">3.1Cookie</font></span
            ></b
          ><b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><o:p></o:p></span
          ></b>
        </h2>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >当您使用本产品或服务时，为使您获得更轻松的访问体验，我们可能会使用各种技术来收集和存储信息，在此过程中可能会向您的设备发送一个或多个</font
            ><font face="PingFang SC Regular"
              >&nbsp;Cookie或匿名标识符。这么做是为了了解您的使用习惯，使您省去重复输入注册信息等步骤。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >当您使用本产品或服务时，我们可能会利用</font
            ><font face="PingFang SC Regular"
              >&nbsp;Cookie和同类技术收取您的信息用于了解您的偏好，进行咨询或数据分析，改善产品服务即用户体验，提高广告效果，及时发现并防范安全风险，为用户和合作伙伴提供更好的服务。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">我们不会将</font
            ><font face="PingFang SC Regular"
              >&nbsp;Cookie用于本隐私政策所述目的之外的用途，您可以根据自己的偏好留存或删除&nbsp;Cookie。您可清除软件或网页中保存的所有
              Cookie</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <h2
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 15.65pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 1gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><font face="PingFang SC Semibold">3.2 SDK的使用</font></span
            ></b
          ><b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><o:p></o:p></span
          ></b>
        </h2>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >为保障我们客户端的稳定运行、功能实现，使您能够使用和享受更多的服务及功能，我们的应用中可能会嵌入授权合作伙伴的</font
            ><font face="PingFang SC Regular"
              >SDK或其他类似的应用程序。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >我们会对授权合作伙伴获取有关信息的应用程序接口（</font
            ><font face="PingFang SC Regular"
              >API）、软件工具开发包（SDK）进行必要的安全检测，并与授权合作伙伴约定相关的数据保护措施，令其按照不低于本政策的信息、数据保密和安全措施来处理个人信息。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">我们的产品集成了以下第三方</font
            ><font face="PingFang SC Regular"
              >SDK：极光推送、高德地图/定位、华为推送(安卓)、华为联运(安卓)、友盟+SDK、DCloud
              uni-app</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold"
                >极光推送收集使用的个人信息包括：</font
              ></span
            ></b
          ><b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><o:p></o:p></span
          ></b>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >1.
              设备信息：用于识别唯一用户，保证消息推送的精准送达；优化推送通道资源，我们会根据设备上不同APP的活跃情况，整合消息推送的通道资源，为开发者提高消息送达率；为开发者提供智能标签以及展示业务统计信息的服务；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >2.
              网络信息与位置信息：优化SDK与极光服务器的网络连接请求，保证服务的稳定性和连续性；实现区域推送的功能。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">高德地图</font
            ><font face="PingFang SC Regular"
              >/定位收集使用的个人信息包括：</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            >1. <span class="mark">位置信息、设备信息（包括IMEI、IDFA、Android
              ID、MEID、MAC地址、OAID、IMSI、硬件序列号）</span>。</span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold"
                >华为会收集以下信息：</font
              ></span
            ></b
          ><b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><o:p></o:p></span
          ></b>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >1.
              <span class="mark">AAID</span>（应用匿名标识）、<span class="mark">应用Token、Topic订阅关系</span>，如果您选择了数据处理位置，则按照您选择的数据处理位置进行数据处理；否则以上信息将不被进行处理。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >2.
              <span class="mark">消息下发记录、Token申请记录、显示/点击/关闭打点上报记录</span>，需要您开启华为分析能力并且选择数据处理位置，才会进行数据处理，否则不进行数据处理。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >3. <span class="mark">缓存消息内容</span>，根据客户端所在的大区进行数据处理。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >4.
              如果您的应用推送消息时，启用了基于华为帐号的消息校验功能，我们将在该场景下收集手机用户的<span class="mark">标识符HMS
              Core openId，HMS Core
              openId</span>将进行不可逆加密仅保存在设备端侧。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >5. 您发送的消息内容将被保存到最终用户设备端侧。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">友盟</font
            ><font face="PingFang SC Regular"
              >+SDK需要收集您的<span class="mark">设备Mac地址、唯一设备识别码（IMEI/android
              ID/IDFA/OPENUDID/GUID、SIM 卡 IMSI
              信息）</span>以提供统计分析服务，并通过地理位置校准报表数据准确性，提供基础反作弊能力。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">友盟</font
            ><font face="PingFang SC Regular"
              >+SDK会向log.umsns.com传输/传送您的<span class="mark">IMEI个人信息</span>等，以便为您提供崩溃统计服务。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">我们的产品基于</font
            ><font face="PingFang SC Regular"
              >DCloud uni-app(5+
              App/Wap2App)开发，应用运行期间需要收集您的<span class="mark">设备唯一识别码（IMEI/android
              ID/DEVICE_ID/IDFA、SIM 卡 IMSI
              信息、OAID）</span>以提供统计分析服务，并通过应用启动数据及异常错误日志分析改进性能和用户体验，为用户提供更好的服务。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <h2
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 2;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><font face="PingFang SC Semibold"
                >3.3网站信标、像素标签和ETag(实体标签)</font
              ></span
            ></b
          ><b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><o:p></o:p></span
          ></b>
        </h2>
        <p
          class="p"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              color: rgb(0, 0, 0);
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">除</font>
            <font face="PingFang SC Regular"
              >Cookie
              外，我们还会在网站上使用网站信标、像素标签、ETag(实体标签)等其他同类技术。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              color: rgb(0, 0, 0);
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="p"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              color: rgb(0, 0, 0);
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >例如，我们向您发送的电子邮件可能含有链接至我们网站内容的地址链接，如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好，以便于我们主动改善客户服务体验。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              color: rgb(0, 0, 0);
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="p"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 21pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              color: rgb(0, 0, 0);
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >ETag(实体标签)是在互联网浏览器与互联网服务器之间背后传送的HTTP协议标头，可代替Cookie。ETag(实体标签)可以帮助我们避免不必要的服务器负载，提高服务效率，节省资源、能源，同时，我们可能通过ETag(实体标签)来记录您的身份，以便我们可以更深入地了解和改善我们的产品或服务。</font
            ></span
          ><u
            ><span
              class="21"
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Regular';
                color: rgb(0, 0, 0);
                mso-ansi-font-weight: normal;
                mso-bidi-font-weight: bold;
                text-decoration: underline;
                text-underline: single;
                font-size: 14px;
              "
              ><font face="PingFang SC Regular"
                >大多数浏览器均为用户提供了清除浏览器缓存数据的功能，您可以在浏览器设置功能中进行相应的数据清除操作。</font
              ></span
            ></u
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              color: rgb(0, 0, 0);
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">但请注意，如果停用</font
            ><font face="PingFang SC Regular"
              >ETag(实体标签)，您可能无法享受相对更佳的产品或服务体验。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <h1
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 1;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 16px;
                mso-font-kerning: 22pt;
              "
              ><font face="PingFang SC Semibold"
                >4、我们如何共享、转让、公开披露您的个人信息</font
              ></span
            ></b
          ><b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 16px;
                mso-font-kerning: 22pt;
              "
              ><o:p></o:p></span
          ></b>
        </h1>
        <h2
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 2;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><font face="PingFang SC Semibold">4.1共享</font></span
            ></b
          ><b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><o:p></o:p></span
          ></b>
        </h2>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >除以下情形外，未经您的同意，我们不会与任何第三方共享您的个人信息：</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 24pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >随着我们业务的持续发展，我们有可能进行合并、收购、资产转让或类似的交易，您的</font
            ><font face="PingFang SC Regular"
              >&nbsp;个人信息有可能作为此类交易的一部分而被转移，我们将在转移前通知您，我们将按照法律法规及不低于本隐私政策所要求的标准继续保护或要求新的控制者继续保护你的个人信息。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >未经您本人允许，我们不会向任何第三方共享、转让、公开披露您的个人信息，下列情形除外：</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">(i)</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >事先获得您的明确授权同意；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">(ii)</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >您自行向第三方共享、转让、公开的；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">(iii)</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >与国家安全、国防安全、公共安全、公共卫生、公共利益直接相关的；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">(iv)</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >根据适用的法律法规、法规程序的要求、强制性的行政司法要求所必须的情况下进行披露或提供，或与犯罪侦查、起诉、审判和判决执行等直接相关的；我们会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查令等。我们将对所有的请求都进行审慎的审查，以确保其具备</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              mso-bidi-font-weight: bold;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">形式</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >合法依据，且仅限于行政、司法部门因特定调查目的有合法权利获取的数据；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">(v)</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >在法律法规允许的范围内，为维护车赢云平台其他用户、公司的生命、财产等合法权益或维权产品或服务的安全稳定运行所必需的，例如查找、预防、处理欺诈等违法活动和减少信用风险等；不过这并不包括违反本隐私政策中所做的承诺而为获利目的对外公开或提供个人信息；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">(vi)</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >公司为维护合法权益而向用户提起诉讼或仲裁；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">(vii)</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >在涉及合并、分立、收购、资产转让或类似的交易时，如涉及到个人信息转让，公司会要求新的持有您的个人信息的公司、组织继续受本隐私政策的约束，否则，公司有权要求该公司、组织重新取得您的授权同意；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">(viii)</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >从合法公开披露的信息中个人信息的，如合法的新闻报道、政府信息公开等渠道；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">(ix)</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >为学术研究目的，或为学研究机构，出于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">(x)</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >法律法规规定的其他情形</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              mso-bidi-font-weight: bold;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">与关联公司间共享：</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >与关联公司间共享：为便于我们基于关联账号共同向您提供服务，保护本产品或服务提供者的关联公司或其他用户或公众的人身财产安全免遭侵害，您的个人信息可能会与我们的关联公司共享。我们只会共享必要的个人信息</font
            ><font face="PingFang SC Regular"
              >(如为便于您使用车赢云平台账号使用我们关联公司产品或服务，我们会向关联公司共享您必要的账户信息)，如果我们共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              mso-bidi-font-weight: bold;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">与授权合作伙伴共享：</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >仅为实现本隐私权政策中声明的目的，我们的某些服务将由我们和授权合作伙伴共同提供。我们可能会与授权合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的授权合作伙伴无权将共享的个人信息用于与产品或服务无关的其他用途。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >目前，我们的授权合作伙伴包括以下</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              mso-bidi-font-weight: bold;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">类型</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">：</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">(i)</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >软件服务提供商、智能设备提供商和系统服务提供商</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 24pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >本产品与第三方软件、设备系统结合为您提供基于位置的服务时，可能会基于您对系统定位的授权及设定，收取您的位置信息及设备信息并提供给系统。同时根据需要可能也会包含您另行填写的注册账号、昵称、等个人信息。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              mso-bidi-font-weight: bold;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >如果您拒绝此类信息收集及使用，您需要在设备系统中进行设置或关闭提供服务的软件，本产品无法通过自动或手动设置关闭相关服务。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              mso-bidi-font-weight: bold;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">(ii)</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >供应商、服务提供商和其他合作伙伴</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 24pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >我们可能将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括提供技术基础设施服务、提供产品内或产品链接后的功能型服务，分析我们服务的使用方式、衡量广告和服务的有效性、提供客户服务和调查。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              mso-bidi-font-weight: bold;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">(iii)</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >广告、分析服务类的授权合作伙伴</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 24pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >未经您的授权，我们不会将您的个人信息与提供广告、分析服务的合作伙伴共享。</font
            ></span
          >
        </p>
        <h2
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 2;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><font face="PingFang SC Semibold">4.2转让</font></span
            ></b
          ><b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><o:p></o:p></span
          ></b>
        </h2>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 15.65pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 1gd;
            mso-para-margin-left: 0gd;
            text-indent: 24pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 15.65pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 1gd;
            mso-para-margin-left: 0gd;
            text-indent: 24pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >在我们发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私权政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <h2
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 2;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><font face="PingFang SC Semibold">4.3公开披露</font></span
            ></b
          ><b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><o:p></o:p></span
          ></b>
        </h2>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >我们仅会在以下情况下，公开披露您的个人信息：</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 24pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >如果我们确定您出现违反法律法规或严重违反本产品相关协议规则的情况，或为保护本产品及其关联公司用户或公众的人身财产安全免遭侵害，我们可能依据法律法规或本产品相关协议规则征得您同意的情况下披露关于您的个人信息，包括相关违规行为以及本产品已对您采取的措施。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <h2
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 2;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><font face="PingFang SC Semibold"
                >4.4共享、转让、公开披露个人信息时事先征得授权同意的例外</font
              ></span
            ></b
          ><b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><o:p></o:p></span
          ></b>
        </h2>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >与国家安全、国防安全有关的；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >与公共安全、公共卫生、重大公共利益有关的；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >与犯罪侦查、起诉、审判和判决执行等有关的；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人的同意的。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >您自行向社会公众公开的个人信息；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <h1
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 1;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 16px;
                mso-font-kerning: 22pt;
              "
              ><font face="PingFang SC Semibold"
                >5、我们如何保护您的个人信息安全</font
              ></span
            ></b
          ><b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 16px;
                mso-font-kerning: 22pt;
              "
              ><o:p></o:p></span
          ></b>
        </h1>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">5.1</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >本产品会采取适当的符合业界标准的安全措施和技术手段存储和保护您的个人信息，以防止其丢失、被误用、受到未授权访问或泄漏、被篡改或毁坏，如通过网络安全层技术</font
            ><font face="PingFang SC Regular"
              >SSL进行加密传输、信息加密存储、严格限制数据中心的访问、使用专用网络通道及网络代理。您的个人信息存放在有密码控制的服务器中，访问均是受到限制的。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">5.2</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >我们对可能接触到您的信息的工作人员采用最小够用授权原则。即我们仅允许有必要知晓这些信息的本产品和其关联公司的员工在采取合理的措施验证身份之后，访问或修改这些信息。同时，我们会严格要求他们履行保密及安全义务，如果未能履行这些义务，其会被追究法律责任或被终止与本产品的合作关系。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">5.3</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >为了保护您的信息安全，如果用户个人信息有误，本产品会在严格验证并核实申请人身份后，根据用户要求访问、修正或删除相关信息</font
            ><font face="PingFang SC Regular"
              >(除非本产品出于合法的原因而必须保留这些个人信息)。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">5.4</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本隐私权政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                font-weight: bold;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">5.5</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >互联网并非绝对安全的环境，电子邮件、即时通讯、社交软件、交易平台等与其他用户的交流方式无法确定是否完全加密，请您在进行交互使用时，注意保护您个人信息的安全。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">5.6</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >请您理解，由于计算机及互联网技术的飞速发展及同步速度的限制，可能存在或出现各种恶意或非恶意的攻击手段。虽然本产品持续致力于提升和加强安全措施，以保护您的信息免遭意外或破坏，但仍无法始终保证信息的百分之百安全。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">5.7</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >您使用产品或服务时所用的系统和通讯网络，或硬件设备等本产品均无法控制，请您了解并注意保护您的个人信息安全。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">5.8</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >请注意，您在使用我们产品和服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息，如您在评价时选择上传包含个人信息的图片。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              mso-bidi-font-weight: bold;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">5.9</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >我们将尽力保障您发送给我们的任何信息的安全性。如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">5.10</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >在不幸发生个人信息安全事件后，我们将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">5.11</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >请您知悉：车赢云平台提供的个人信息保护措施仅适用于车赢云平台产品和服务，一旦您离开车赢云平台，浏览或使用其他网站、服务及内容资源，我们即没有能力及义务保护您在车赢云平台之外的网站提交的任何个人信息，无论您登录或浏览上述网站是否基于车赢云平台的链接或引导。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <h1
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 1;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 16px;
                mso-font-kerning: 22pt;
              "
              ><font face="PingFang SC Semibold"
                >6、您如何管理您的个人信息</font
              ></span
            ></b
          ><b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 16px;
                mso-font-kerning: 22pt;
              "
              ><o:p></o:p></span
          ></b>
        </h1>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >您可以通过以下方式访问及管理您的个人信息：</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <h2
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 2;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><font face="PingFang SC Semibold"
                >6.1访问您的个人信息</font
              ></span
            ></b
          ><b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><o:p></o:p></span
          ></b>
        </h2>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >您有权访问您的个人信息，法律法规规定的例外情况除外。您可以通过以下方式自行访问您的个人信息：</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">账户信息</font
            ><font face="PingFang SC Regular"
              >——如果您希望访问或编辑您的账户中的个人基本资料信息，您可以通过登录您的账号执行此类操作。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >如果您无法通过上述路径访问该等个人信息，您可以随时通过车赢云平台客服与我们取得联系。我们将在</font
            ><font face="PingFang SC Regular"
              >30天内回复您的访问请求。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >对于您在使用我们的产品或服务过程中产生的其他个人信息，我们将根据本条</font
            ><font face="PingFang SC Regular"
              >“6.7响应您的上述请求”中的相关安排向您提供。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <h2
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 2;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><font face="PingFang SC Semibold"
                >6.2更正或补充您的个人信息</font
              ></span
            ></b
          ><b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><o:p></o:p></span
          ></b>
        </h2>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 15.65pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 1gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正或补充。您可以通过</font
            ><font face="PingFang SC Regular"
              >“6.1访问您的个人信息”中列明的方式提出更正或补充申请。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <h2
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 2;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><font face="PingFang SC Semibold"
                >6.3删除您的个人信息</font
              ></span
            ></b
          ><b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><o:p></o:p></span
          ></b>
        </h2>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">您可以通过</font
            ><font face="PingFang SC Regular"
              >“6.1访问您的个人信息”中列明的方式删除您的部分个人信息。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >在以下情形中，您可以向我们提出删除个人信息的请求：</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              mso-bidi-font-weight: bold;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">(i)</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >如果我们处理个人信息的行为违反法律法规；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              mso-bidi-font-weight: bold;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">(ii)</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >如果我们收集、使用您的个人信息，却未征得您的明确同意；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              mso-bidi-font-weight: bold;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">(iii)</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >如果我们处理个人信息的行为严重违反了与您的约定；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              mso-bidi-font-weight: bold;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">(iv)</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >如果您不再使用我们的产品或服务，或您主动注销了账号；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              mso-bidi-font-weight: bold;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">(v)</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >如果我们永久不再为您提供产品或服务。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，要求其及时删除，除非法律法规另有规定，或这些主体获得您的独立授权。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >当您从我们的服务中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <h2
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 2;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><font face="PingFang SC Semibold"
                >6.4改变您授权同意的范围</font
              ></span
            ></b
          ><b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><o:p></o:p></span
          ></b>
        </h2>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >每个业务功能需要一些基本的个人信息才能得以完成</font
            ><font face="PingFang SC Regular"
              >(详见本隐私权政策其他部分)。除此之外，您可以通过解除绑定、修改个人设置、删除相关信息等方式撤回部分授权，也可以通过关闭功能的方式撤销部分授权。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <h2
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 2;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><font face="PingFang SC Semibold"
                >6.5个人信息主体注销账户</font
              ></span
            ></b
          ><b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><o:p></o:p></span
          ></b>
        </h2>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">您可以通过所属企业在</font
            ><font face="PingFang SC Regular"
              >“企业管理后台组织架构功能”页面提交删除员工信息(即账户注销)申请。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 15.65pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 1gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >在您的所属企业为您注销账户之后，我们将停止为您提供产品或服务，根据适用法律的要求删除您的个人信息，或使其匿名化处理。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <h2
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 2;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><font face="PingFang SC Semibold"
                >6.6约束信息系统自动决策</font
              ></span
            ></b
          ><b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><o:p></o:p></span
          ></b>
        </h2>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 15.65pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 1gd;
            mso-para-margin-left: 0gd;
            text-indent: 24pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将在不侵害本产品商业秘密或其他用户权益、社会公共利益的前提下提供申诉方法。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <h2
          align="justify"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            page-break-after: avoid;
            mso-pagination: lines-together;
            text-align: justify;
            text-justify: inter-ideograph;
            mso-outline-level: 2;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><font face="PingFang SC Semibold"
                >6.7响应您的上述请求</font
              ></span
            ></b
          ><b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 1pt;
              "
              ><o:p></o:p></span
          ></b>
        </h2>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">我们将在</font
            ><font face="PingFang SC Regular"
              >30天内做出答复。如您不满意，还可以通过本隐私政策第10条载明的客服联系方式发起投诉。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段</font
            ><font face="PingFang SC Regular"
              >(例如，需要开发新系统或从根本上改变现行惯例)、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              mso-bidi-font-weight: bold;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >在以下情形中，按照法律法规要求，我们将无法响应您的请求：</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">(i)</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >与国家安全、国防安全有关的；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">(ii)</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >与公共安全、公共卫生、重大公共利益有关的；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">(iii)</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >与犯罪侦查、起诉、审判和执行判决等有关的；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">(iv)</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >有充分证据表明个人信息主体存在主观恶意或滥用权利的；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">(v)</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 15.65pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 1gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">(vi)</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">涉及商业秘密的。</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <h1
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 15.65pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 1gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 16px;
                mso-font-kerning: 22pt;
              "
              ><font face="PingFang SC Semibold"
                >8、本隐私权政策如何更新</font
              ></span
            ></b
          ><b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 16px;
                mso-font-kerning: 22pt;
              "
              ><o:p></o:p></span
          ></b>
        </h1>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold"
                >8.1我们的隐私权政策可能变更。</font
              ></span
            ></b
          ><b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><o:p></o:p></span
          ></b>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">8.2</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >未经您明确同意，我们不会限制您按照本隐私权政策所应享有的权利。我们会在专门页面</font
            ><font face="PingFang SC Regular"
              >(我的-设置关于-隐私权政策)发布对隐私权政策所做的任何变更。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">8.3</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >对于重大变更，我们还会提供更为显著的通知</font
            ><font face="PingFang SC Regular"
              >(包括我们会通过车赢云平台网站公示的方式进行通知甚至向您提供弹窗提示)。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >本隐私权政策所指的重大变更包括但不限于：</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >我们在控制权等方面发生重大变化。如并购重组等引起的所有者变更等；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >个人信息共享、转让或公开披露的主要对象发生变化；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >您参与个人信息处理方面的权利及其行使方式发生重大变化；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 15.65pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 1gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >个人信息安全影响评估报告表明存在高风险时。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <h1
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 15.65pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 1gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 16px;
                mso-font-kerning: 22pt;
              "
              ><font face="PingFang SC Semibold">9、如何联系我们</font></span
            ></b
          ><b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 16px;
                mso-font-kerning: 22pt;
              "
              ><o:p></o:p></span
          ></b>
        </h1>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >您可以通过以下方式与我们联系，我们将在</font
            ><font face="PingFang SC Regular">30天内回复您的请求：</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 7.85pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 0.5gd;
            mso-para-margin-left: 0gd;
            text-indent: 0pt;
            mso-char-indent-count: 0;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">9.1</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >如对本隐私权政策内容有任何疑问、意见或建议，您都可以通过拨打我们的客服电话</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              mso-bidi-font-weight: bold;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">400-0226-698</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">或通过发送邮件至</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              mso-bidi-font-weight: bold;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >&nbsp;service@carwins.com</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">与我们联系。</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold">9.2</font></span
            ></b
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              mso-bidi-font-weight: bold;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">本隐私政策不适用情况</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >本隐私政策不适用于以下情况：</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">通过本产品而接入的第三方服务</font
            ><font face="PingFang SC Regular"
              >(包括任何第三方应用及网站)收集的信息；</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >通过在本产品中提供广告服务的其他公司或机构、组织所收集的信息。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 24pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular"
              >我们的服务可能包括或链接至第三方提供的信息或其他服务</font
            ><font face="PingFang SC Regular"
              >(包括网站)。该等第三方服务可能由相关的第三方运营。您使用该等第三方服务(包括您向该等第三方提供的任何个人信息)，须受该第三方的服务条款及隐私条款(而非本隐私权政策)约束，您需要仔细阅读其条款。请您妥善保护自己的个人信息，仅在必要的情况下向他人提供。本隐私权政策仅适用于我们所收集、保存、使用、共享、披露信息，并不适用于任何第三方提供的服务或第三方的信息使用规则，我们对任何第三方使用由您提供的信息不承担任何责任。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-left: 0gd;
            text-indent: 24pt;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">我们</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              mso-bidi-font-weight: bold;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">谨此</font></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><font face="PingFang SC Regular">再次提醒您，您点击</font
            ><font face="PingFang SC Regular"
              >“新用户注册”(首次注册的新用户)或“直接登录”(已经注册的用户)按钮即视为您完全接受本协议，同意我们根据本协议收集储存和使用您的个人信息和其他本协议列明的信息，在点击之前请您再次确认已知悉并完全理解本协议的全部内容。</font
            ></span
          ><span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 0pt;
            "
            ><o:p></o:p
          ></span>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 15.65pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 1gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            mso-pagination: widow-orphan;
            text-align: left;
            line-height: 120%;
          "
        >
          <b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><font face="PingFang SC Semibold"
                >上海车赢信息服务有限公司</font
              ></span
            ></b
          ><b style="mso-bidi-font-weight: normal"
            ><span
              style="
                mso-spacerun: 'yes';
                font-family: 'PingFang SC Semibold';
                line-height: 120%;
                mso-ansi-font-weight: bold;
                mso-bidi-font-weight: normal;
                font-size: 14px;
                mso-font-kerning: 0pt;
              "
              ><o:p></o:p></span
          ></b>
        </p>
        <p
          class="MsoNormal"
          style="
            margin-top: 7.85pt;
            margin-right: 0pt;
            margin-bottom: 15.65pt;
            margin-left: 0pt;
            mso-para-margin-top: 0.5gd;
            mso-para-margin-right: 0gd;
            mso-para-margin-bottom: 1gd;
            mso-para-margin-left: 0gd;
            text-autospace: ideograph-numeric;
            line-height: 120%;
          "
        >
          <span
            style="
              mso-spacerun: 'yes';
              font-family: 'PingFang SC Regular';
              line-height: 120%;
              mso-ansi-font-weight: normal;
              font-size: 14px;
              mso-font-kerning: 1pt;
            "
            ><o:p>&nbsp;</o:p></span
          >
        </p>
      </div>
      <!--EndFragment-->
    </body>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
<style lang='scss' scoped>
.ml_agreement {
  padding: 15px;
  p,
  span {
    color: #292d33 !important;
    line-height: 26px !important;
  }
}
@font-face {
  font-family: "Times New Roman";
}

@font-face {
  font-family: "宋体";
}

@font-face {
  font-family: "Calibri";
}

@font-face {
  font-family: "等线";
}

@font-face {
  font-family: "Wingdings";
}

@font-face {
  font-family: "Calibri Light";
}

@font-face {
  font-family: "等线 Light";
}

@list l0:level1 {
  mso-level-number-format: alpha-lower;
  mso-level-suffix: tab;
  mso-level-text: "(%1)";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 36pt;
  text-indent: -18pt;
  font-family: "Times New Roman";
}

@list l0:level2 {
  mso-level-number-format: alpha-lower;
  mso-level-suffix: tab;
  mso-level-text: "%2.";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 72pt;
  text-indent: -18pt;
  font-family: "Times New Roman";
}

@list l0:level3 {
  mso-level-number-format: lower-roman;
  mso-level-suffix: tab;
  mso-level-text: "%3.";
  mso-level-tab-stop: none;
  mso-level-number-position: right;
  margin-left: 108pt;
  text-indent: -9pt;
  font-family: "Times New Roman";
}

@list l0:level4 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%4.";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 144pt;
  text-indent: -18pt;
  font-family: "Times New Roman";
}

@list l0:level5 {
  mso-level-number-format: alpha-lower;
  mso-level-suffix: tab;
  mso-level-text: "%5.";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 180pt;
  text-indent: -18pt;
  font-family: "Times New Roman";
}

@list l0:level6 {
  mso-level-number-format: lower-roman;
  mso-level-suffix: tab;
  mso-level-text: "%6.";
  mso-level-tab-stop: none;
  mso-level-number-position: right;
  margin-left: 216pt;
  text-indent: -9pt;
  font-family: "Times New Roman";
}

@list l0:level7 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%7.";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 252pt;
  text-indent: -18pt;
  font-family: "Times New Roman";
}

@list l0:level8 {
  mso-level-number-format: alpha-lower;
  mso-level-suffix: tab;
  mso-level-text: "%8.";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 288pt;
  text-indent: -18pt;
  font-family: "Times New Roman";
}

@list l0:level9 {
  mso-level-number-format: lower-roman;
  mso-level-suffix: tab;
  mso-level-text: "%9.";
  mso-level-tab-stop: none;
  mso-level-number-position: right;
  margin-left: 324pt;
  text-indent: -9pt;
  font-family: "Times New Roman";
}

@list l1:level1 {
  mso-level-number-format: alpha-lower;
  mso-level-suffix: tab;
  mso-level-text: "(%1)";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 36pt;
  text-indent: -18pt;
  font-family: "Times New Roman";
}

@list l1:level2 {
  mso-level-number-format: alpha-lower;
  mso-level-suffix: tab;
  mso-level-text: "%2.";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 72pt;
  text-indent: -18pt;
  font-family: "Times New Roman";
}

@list l1:level3 {
  mso-level-number-format: lower-roman;
  mso-level-suffix: tab;
  mso-level-text: "%3.";
  mso-level-tab-stop: none;
  mso-level-number-position: right;
  margin-left: 108pt;
  text-indent: -9pt;
  font-family: "Times New Roman";
}

@list l1:level4 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%4.";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 144pt;
  text-indent: -18pt;
  font-family: "Times New Roman";
}

@list l1:level5 {
  mso-level-number-format: alpha-lower;
  mso-level-suffix: tab;
  mso-level-text: "%5.";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 180pt;
  text-indent: -18pt;
  font-family: "Times New Roman";
}

@list l1:level6 {
  mso-level-number-format: lower-roman;
  mso-level-suffix: tab;
  mso-level-text: "%6.";
  mso-level-tab-stop: none;
  mso-level-number-position: right;
  margin-left: 216pt;
  text-indent: -9pt;
  font-family: "Times New Roman";
}

@list l1:level7 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%7.";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 252pt;
  text-indent: -18pt;
  font-family: "Times New Roman";
}

@list l1:level8 {
  mso-level-number-format: alpha-lower;
  mso-level-suffix: tab;
  mso-level-text: "%8.";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 288pt;
  text-indent: -18pt;
  font-family: "Times New Roman";
}

@list l1:level9 {
  mso-level-number-format: lower-roman;
  mso-level-suffix: tab;
  mso-level-text: "%9.";
  mso-level-tab-stop: none;
  mso-level-number-position: right;
  margin-left: 324pt;
  text-indent: -9pt;
  font-family: "Times New Roman";
}

@list l2:level1 {
  mso-level-start-at: 8;
  mso-level-number-format: alpha-lower;
  mso-level-suffix: tab;
  mso-level-text: "(%1)";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 36pt;
  text-indent: -18pt;
  font-family: "Times New Roman";
}

@list l2:level2 {
  mso-level-number-format: alpha-lower;
  mso-level-suffix: tab;
  mso-level-text: "%2.";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 72pt;
  text-indent: -18pt;
  font-family: "Times New Roman";
}

@list l2:level3 {
  mso-level-number-format: lower-roman;
  mso-level-suffix: tab;
  mso-level-text: "%3.";
  mso-level-tab-stop: none;
  mso-level-number-position: right;
  margin-left: 108pt;
  text-indent: -9pt;
  font-family: "Times New Roman";
}

@list l2:level4 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%4.";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 144pt;
  text-indent: -18pt;
  font-family: "Times New Roman";
}

@list l2:level5 {
  mso-level-number-format: alpha-lower;
  mso-level-suffix: tab;
  mso-level-text: "%5.";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 180pt;
  text-indent: -18pt;
  font-family: "Times New Roman";
}

@list l2:level6 {
  mso-level-number-format: lower-roman;
  mso-level-suffix: tab;
  mso-level-text: "%6.";
  mso-level-tab-stop: none;
  mso-level-number-position: right;
  margin-left: 216pt;
  text-indent: -9pt;
  font-family: "Times New Roman";
}

@list l2:level7 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%7.";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 252pt;
  text-indent: -18pt;
  font-family: "Times New Roman";
}

@list l2:level8 {
  mso-level-number-format: alpha-lower;
  mso-level-suffix: tab;
  mso-level-text: "%8.";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 288pt;
  text-indent: -18pt;
  font-family: "Times New Roman";
}

@list l2:level9 {
  mso-level-number-format: lower-roman;
  mso-level-suffix: tab;
  mso-level-text: "%9.";
  mso-level-tab-stop: none;
  mso-level-number-position: right;
  margin-left: 324pt;
  text-indent: -9pt;
  font-family: "Times New Roman";
}

@list l3:level1 {
  mso-level-number-format: alpha-lower;
  mso-level-suffix: tab;
  mso-level-text: "(%1)";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 36pt;
  text-indent: -18pt;
  font-family: "Times New Roman";
}

@list l3:level2 {
  mso-level-number-format: alpha-lower;
  mso-level-suffix: tab;
  mso-level-text: "%2.";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 72pt;
  text-indent: -18pt;
  font-family: "Times New Roman";
}

@list l3:level3 {
  mso-level-number-format: lower-roman;
  mso-level-suffix: tab;
  mso-level-text: "%3.";
  mso-level-tab-stop: none;
  mso-level-number-position: right;
  margin-left: 108pt;
  text-indent: -9pt;
  font-family: "Times New Roman";
}

@list l3:level4 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%4.";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 144pt;
  text-indent: -18pt;
  font-family: "Times New Roman";
}

@list l3:level5 {
  mso-level-number-format: alpha-lower;
  mso-level-suffix: tab;
  mso-level-text: "%5.";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 180pt;
  text-indent: -18pt;
  font-family: "Times New Roman";
}

@list l3:level6 {
  mso-level-number-format: lower-roman;
  mso-level-suffix: tab;
  mso-level-text: "%6.";
  mso-level-tab-stop: none;
  mso-level-number-position: right;
  margin-left: 216pt;
  text-indent: -9pt;
  font-family: "Times New Roman";
}

@list l3:level7 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%7.";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 252pt;
  text-indent: -18pt;
  font-family: "Times New Roman";
}

@list l3:level8 {
  mso-level-number-format: alpha-lower;
  mso-level-suffix: tab;
  mso-level-text: "%8.";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 288pt;
  text-indent: -18pt;
  font-family: "Times New Roman";
}

@list l3:level9 {
  mso-level-number-format: lower-roman;
  mso-level-suffix: tab;
  mso-level-text: "%9.";
  mso-level-tab-stop: none;
  mso-level-number-position: right;
  margin-left: 324pt;
  text-indent: -9pt;
  font-family: "Times New Roman";
}

@list l4:level1 {
  mso-level-number-format: alpha-lower;
  mso-level-suffix: tab;
  mso-level-text: "(%1)";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 36pt;
  text-indent: -18pt;
  font-family: "Times New Roman";
}

@list l4:level2 {
  mso-level-number-format: alpha-lower;
  mso-level-suffix: tab;
  mso-level-text: "%2.";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 72pt;
  text-indent: -18pt;
  font-family: "Times New Roman";
}

@list l4:level3 {
  mso-level-number-format: lower-roman;
  mso-level-suffix: tab;
  mso-level-text: "%3.";
  mso-level-tab-stop: none;
  mso-level-number-position: right;
  margin-left: 108pt;
  text-indent: -9pt;
  font-family: "Times New Roman";
}

@list l4:level4 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%4.";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 144pt;
  text-indent: -18pt;
  font-family: "Times New Roman";
}

@list l4:level5 {
  mso-level-number-format: alpha-lower;
  mso-level-suffix: tab;
  mso-level-text: "%5.";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 180pt;
  text-indent: -18pt;
  font-family: "Times New Roman";
}

@list l4:level6 {
  mso-level-number-format: lower-roman;
  mso-level-suffix: tab;
  mso-level-text: "%6.";
  mso-level-tab-stop: none;
  mso-level-number-position: right;
  margin-left: 216pt;
  text-indent: -9pt;
  font-family: "Times New Roman";
}

@list l4:level7 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%7.";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 252pt;
  text-indent: -18pt;
  font-family: "Times New Roman";
}

@list l4:level8 {
  mso-level-number-format: alpha-lower;
  mso-level-suffix: tab;
  mso-level-text: "%8.";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 288pt;
  text-indent: -18pt;
  font-family: "Times New Roman";
}

@list l4:level9 {
  mso-level-number-format: lower-roman;
  mso-level-suffix: tab;
  mso-level-text: "%9.";
  mso-level-tab-stop: none;
  mso-level-number-position: right;
  margin-left: 324pt;
  text-indent: -9pt;
  font-family: "Times New Roman";
}

p.MsoNormal {
  mso-style-name: 正文;
  mso-style-parent: "";
  margin: 0pt;
  margin-bottom: 0.0001pt;
  font-family: Calibri;
  mso-fareast-font-family: 等线;
  mso-bidi-font-family: "Times New Roman";
  font-size: 14px;
}

h1 {
  mso-style-name: "标题 1";
  mso-style-next: 正文;
  margin-top: 12pt;
  page-break-after: avoid;
  mso-pagination: lines-together;
  mso-outline-level: 1;
  font-family: "Calibri Light";
  mso-fareast-font-family: "等线 Light";
  mso-bidi-font-family: "Times New Roman";
  color: rgb(47, 84, 150);
  font-size: 16pt;
}

h2 {
  mso-style-name: "标题 2";
  mso-style-noshow: yes;
  mso-style-next: 正文;
  margin-top: 2pt;
  page-break-after: avoid;
  mso-pagination: lines-together;
  mso-outline-level: 2;
  font-family: "Calibri Light";
  mso-fareast-font-family: "等线 Light";
  mso-bidi-font-family: "Times New Roman";
  color: rgb(47, 84, 150);
  font-size: 13pt;
}

p.MsoTitle {
  mso-style-name: 标题;
  mso-style-next: 正文;
  margin: 0pt;
  margin-bottom: 0.0001pt;
  mso-add-space: auto;
  font-family: "Calibri Light";
  mso-fareast-font-family: "等线 Light";
  mso-bidi-font-family: "Times New Roman";
  letter-spacing: -0.5pt;
  font-size: 28pt;
  mso-font-kerning: 14pt;
}

p.MsoFooter {
  mso-style-name: 页脚;
  mso-style-noshow: yes;
  margin: 0pt;
  margin-bottom: 0.0001pt;
  layout-grid-mode: char;
  font-family: Calibri;
  mso-fareast-font-family: 等线;
  mso-bidi-font-family: "Times New Roman";
  font-size: 9pt;
}

p.MsoHeader {
  mso-style-name: 页眉;
  mso-style-noshow: yes;
  margin: 0pt;
  margin-bottom: 0.0001pt;
  border-bottom: 1pt solid windowtext;
  mso-border-bottom-alt: 0.75pt solid windowtext;
  padding: 0pt 0pt 1pt 0pt;
  layout-grid-mode: char;
  text-align: center;
  font-family: Calibri;
  mso-fareast-font-family: 等线;
  mso-bidi-font-family: "Times New Roman";
  font-size: 9pt;
}


span.msoIns {
  mso-style-type: export-only;
  mso-style-name: "";
  text-decoration: underline;
  text-underline: single;
  color: blue;
}

span.msoDel {
  mso-style-type: export-only;
  mso-style-name: "";
  text-decoration: line-through;
  color: red;
}

table.MsoNormalTable {
  mso-style-name: 普通表格;
  mso-style-parent: "";
  mso-style-noshow: yes;
  mso-tstyle-rowband-size: 0;
  mso-tstyle-colband-size: 0;
  mso-padding-alt: 0pt 5.4pt 0pt 5.4pt;
  mso-para-margin: 0pt;
  mso-para-margin-bottom: 0.0001pt;
  mso-pagination: widow-orphan;
  font-family: "Times New Roman";
  font-size: 10pt;
  mso-ansi-language: #0400;
  mso-fareast-language: #0400;
  mso-bidi-language: #0400;
}
table td {
  word-break: break-all; // 设置强行换行 break-all强制英文单词断行
  word-wrap: break-word; // 内容将在边界内换行
}
@page {
  mso-page-border-surround-header: no;
  mso-page-border-surround-footer: no;
}
@page Section0 {
  margin-top: 72pt;
  margin-bottom: 72pt;
  margin-left: 72pt;
  margin-right: 72pt;
  size: 612pt 792pt;
  layout-grid: 18pt;
  mso-header-margin: 35.4pt;
  mso-footer-margin: 35.4pt;
}
div.Section0 {
  page: Section0;
}
.ml_agreement .mark{
  font-weight: 600;
  color: #f91221!important;
}
</style>